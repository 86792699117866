// Here you can add other styles
.sidebar-minimizer,
.sidebar {
  background: #ffffff !important;
  color: #000000 !important;
}

.sidebar .nav-link:hover,
.nav-link.active {
  background: #20a8d8 !important;
  color: #ffffff !important;
}

.nav-link {
  color: #000000 !important;
}

.nav-dropdown.open {
  background: rgba(0, 0, 0, 0) !important;
}

.app-header .navbar-nav {
  margin-right: 30px;
}

.login-error {
  border-color: #f86c6b;
}

.login-error-text {
  color: #f86c6b;
}

.view-label {
  display: inline-block;
  width: 157px;
  color: slategray;
}

.view-title {
  font-size: 22px;
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}

.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.file-custom:after {
  content: "Choose file...";
}
.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.075rem solid #ddd;
  border-radius: 0 0.25rem 0.25rem 0;
}

/* Focus */
.file input:focus ~ .file-custom {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}

.csv-input {
  padding: 10px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.loading {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loading::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #20a8d8;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  -moz-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.custom-form-error {
  color: #f86c6b;
  font-size: 80%;
}

.custom-file-input-label {
  border: 1px solid #20a8d8;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: 0.76562rem;
  border-radius: 0.2rem;
  background-color: #20a8d8;
  margin-bottom: unset;
  color: #fff;
}

.remove-table-border {
  border-top: unset!important;
}
